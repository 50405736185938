<template>
   <div class="m-work-box">
     <bannerStatic :num="5"/>
     <div class="box">
        <div class="joinUs">
          <div class="des-box"><p>{{jobInfoContent}}</p></div>
        </div>
         <h4 class="titleStyle">职位选择</h4>
        <div class="work">
           <a-tabs   :size="'small'" :tabBarStyle="{'text-align':'left'}" v-if="joblist.length>0">
            <a-tab-pane  v-for="item in joblist" :key="item.moduleId+''" :tab="item.moduleName" >
              <p v-for="job in item.jobOffers" :key="job.jobId+''" @click="jump(job.jobId)" class="job-info">{{job.jobName}}<span>{{job.salary}}</span><span>{{job.workPlace}}</span></p>
            </a-tab-pane>
          </a-tabs>
          <p v-if="joblist.length==0">暂无招聘信息</p>
        </div>
        <div class="ourImg">
          <div class="activeBg"><p>精彩活动</p></div>
          <div class="img-box" >
            <a-row >
              <a-col class="box" v-for="item in jobImages" :key="item.jobImageId" >
                <img class="box-in" :src='newsImg+item.imageUrl'  />
              </a-col>
            </a-row>
          </div>
        </div>
        <h4 class="titleStyle">联系我们</h4>
        <div class="contact">
          <div class="info-box">
            <div class="img">    <img src="../../../assets/img/feiji.png" alt="" srcset=""></div>
            <p style="font-size: .48rem">{{email}}</p>
            <br/>
            <div class="img">
               <img src="../../../assets/img/dizhi.png" alt="" srcset="">
            </div>
            <p>{{address}}</p>
          </div>
        </div>
     </div>
   </div>
</template>
<script>
import bannerStatic from'@/components/BannerStatic/mBannerStatic'
import  { newsImg } from '@/config/appSetting'
import { getJob } from '@/api/data'
import moment from 'moment'
export default {
  name:'work',
  components: {
    bannerStatic
  },
   data() {
      return {
        joblist:[],
        email:'',
        address:'',
        jobInfoContent:'',
        newsImg,
        jobImages:[]
      };
    },
    methods: {
      moment,
      jump(id){
        this.$router.push({path:`/MWorkDetails/${id}`})
      },
  },
  mounted(){
    let _this=this
    this.email=sessionStorage.getItem("email")
    this.address=sessionStorage.getItem("address")
    getJob().then(res => {
        if (res.state) {
          if(res.data.jobOffers.length>0){
            _this.joblist=res.data.jobOffers
          }
          if(res.data.jobInfo.length>0){
            _this.jobInfoContent=res.data.jobInfo[0].jobInfoContent
            _this.jobImages=res.data.jobInfo[0].jobImages
          }
        }
     })
  }
}
</script>
<style lang="less" scoped>
.m-work-box{
  background:#fff;
  overflow: hidden;
  .joinUs{
    display: block;
    position: relative;
    z-index: 2;
    margin-bottom: 1.333333rem /* 100/75 */;
    .des-box{
      box-shadow: 0px 2px 14px 0px rgba(103, 138, 195, 0.16);
      margin:-2.333333rem /* 100/75 */ .533333rem /* 40/75 */ 0;
      background: #fff;
      border-radius: 10px;
      margin-bottom: .4rem /* 30/75 */;
      min-height: 8.266667rem /* 620/75 */;
      p{
        margin-bottom: 0; 
        padding: .4rem /* 30/75 */;
        text-align: left;
        font-size: .373333rem /* 28/75 */;
        line-height: 2;
        color: #333333;
        box-sizing: border-box;
      }
    }
  }
  .work{
    margin-bottom:1.333333rem /* 100/75 */;
      .job-info{
        border-bottom: 1px solid #e1e1e1;
        text-align: left;
        margin:0 .266667rem /* 20/75 */;
        padding:.266667rem /* 20/75 */ 0;
        font-size: .373333rem /* 28/75 */;
        color:#333;
        span{
          float: right;
          margin-right: 10px;
          font-size: .32rem /* 24/75 */;
        }
     }
  }
  .ourImg{
    margin-bottom:1.333333rem /* 100/75 */;
    .activeBg{
      display: block;
      height: 3.2rem /* 240/75 */;
      line-height: 3.2rem /* 240/75 */;
      width: 100%;
      background: url(../../../assets/img/join-center.jpg) no-repeat center;
      background-color: #287ee1;
      background-size: cover;
      p{
        display: block;
        text-align: center;
        color:#fff;
        font-size: .48rem /* 36/75 */;
        font-weight: 200;
        font-stretch: normal;
        letter-spacing: 2px;
        margin-bottom: 0;
      }
    }
    img{
      width: 100%;
    }
  }
  .contact{
     background: url(../../../assets/img/mcontactus.jpg) no-repeat center 60%;
     background-size: cover;
     padding: 1.2rem /* 90/75 */ 0;
     .info-box{
       background: #fff;
       margin:0 1.066667rem /* 80/75 */;
       padding:.666667rem /* 50/75 */ .4rem /* 30/75 */;
       font-size: .373333rem /* 28/75 */;
       color: #333333;
       p{
         margin: 0 auto;
         width: 75%;
       }
     }
     .img{
       display: block;
       margin:0 auto .2rem /* 30/75 */ ;
       width: .8rem /* 60/75 */;
       height: .8rem /* 60/75 */;
       border: 1px solid #f9a825;
       border-radius: 50%;
       line-height:.666667rem /* 50/75 */;
       img{
         width: 40%;
       }
     }
  }
  .titleStyle {
    font-size: .48rem /* 36/75 */;
  }
  .titleStyle:after {
    content: '';
    width: .8rem /* 60/75 */;
    height: 3px;
    background: #f9a825;
    display: block;
    margin: .266667rem /* 20/75 */ auto .533333rem /* 40/75 */;
  }
}

</style>
<style>
.m-work-box .ant-tabs .ant-tabs-small-bar .ant-tabs-tab{
  margin-right:0
}
.m-work-box  .ant-tabs-top .ant-tabs-ink-bar-animated{
  width: 0 !important;
}
.m-work-box .ant-tabs-tab-active.ant-tabs-tab {
    position: relative;
}
.m-work-box .ant-tabs-tab-active.ant-tabs-tab:after {
    content: '';
    width: .8rem /* 60/75 */;
    height: 2px;
    background: #f9a825;
    display: block;
    position: absolute;
    bottom: 1px;
    left: 50%;
    margin-left: -.4rem /* 30/75 */;
}
.ant-tabs-nav,.ant-tabs-nav .ant-tabs-tab-active{
    color: #333333 !important;
}
</style>





